<template>
    <b-card v-if="empresas.length > 0" no-body class="p-4 card-login">
        <b-card-body>
        <b-form>
            <h4 class="estilo-empresa">Qual empresa deseja acessar?</h4>
            <b-list-group>
            <b-list-group-item
                v-on:click="useResale(resale)"
                button
                v-for="resale of empresas"
                class="btn-item-empresa margin-bottom"
                :key="resale.cnpj"
            >
                {{ resale.cnpj | cnpj }} -
                {{ resale.razao_social }}
            </b-list-group-item>
            </b-list-group>
        </b-form>
        <b-row>
            <b-col cols="6">
            <b-btn sm v-on:click="voltar" class="btn-voltar" button>
                Voltar
            </b-btn>
            </b-col>
        </b-row>
        </b-card-body>
    </b-card>
    <b-col md="12" v-else>
        <div id="container-aviso">
            <p class="info-aviso"> Por favor entre em contato com o seu consultor ou entre em contato com a equipe de suporte! </p>
            <a :href="retornarLinkWhatsapp()" target="_blank" id="link_whatsapp_suporte">
            <img src="../assets/img/iconewhats.png" alt="Ícone do telefone whatsapp do suporte">
                {{ retornarNumeroMascarado(this.nroSuporte, this.mascara.telefone.regex, this.mascara.telefone.mascara) }}
            </a>
        </div>
    </b-col>
</template>

<style scoped>
    .card {
        background-color: transparent;
        border: none;
    }
    .card-body{
        padding: 0!important;
    }
    .card-login {
        margin-top: 3rem;
        padding: 1em!important;
        border-radius: 1em;
    }
    .estilo-empresa{
        font-size: 1.2rem;
        color: #fff;
    }
    .btn-item-empresa {
        border-radius: 50px;
        border-color: orangered;
    }
    .btn-voltar {
        border-color: rgba(255, 68, 0, 0.897);
        background-color: orangered;
        color: white;
        border-radius: 50px;
        padding: 0 1em;
        width: 100%;
        width: auto;
    }
    .btn-voltar:hover {
        background-color: rgba(255, 68, 0, 0.808);
    }
    .margin-bottom{
        margin-bottom: 15px !important;
    }

    #container-aviso {
        margin-top: 1.5em;
        padding: 2px;
        border-radius: 16px;
        border: 1px #fff solid;    
        backdrop-filter: blur(10px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .info-aviso {
        margin-bottom: 1rem;
        font-size: 24px;
        text-align: center;
        color: #fff;    
    }

    #container-aviso a {
        color: #fff;
        border-radius: 16px;
        border: 1px #fff solid;
        padding: 5px 15px;
        font-size: 20px;
        margin-bottom: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    #container-aviso a img {
        width: 1.2em;
        margin-right: 0.2em;
    }


</style>

<script>
import Carrinho from "../services/carrinho";

export default {

    name: "ResaleSelector",
    props: {
        empresas: {
            type: Array
        },
        usuario: {
            type: String
        },
        dataExpiracao: {
            type: String
        },
        nroSuporte: {
            type: String
        }
    },
    data() {
        return{
            mascara: {
                telefone: {
                    regex: "(\\d{2})(\\d{5})(\\d{4})",
                    mascara: "$1 $2-$3"
                }
            }
        }
    },
    methods: {
        useResale(resale) {
            this.$store.commit("login/CURRENT_RESALE", resale);
            this.$store.commit("login/CURRENT_USER", this.usuario);
            this.$store.commit("login/X_API_KEY", resale.key);
            this.$store.commit("login/EXPIRATION_DATE", this.dataExpiracao);
            this.loadCurrentCart();
            this.$router.push("/");
        },
        loadCurrentCart() {
            this.$store.commit("carrinho/RESET");
            Carrinho.getCarrinho()
            .then(response => {
                this.$store.commit("carrinho/CURRENT_CART", response.data.data);
            })
            .catch(() => {});
        },
        desloga(){
            this.$emit('alterarLoggedIn');
        },
        voltar() {
            this.desloga();
            this.$router.push({ name: 'Login' });
        },
    },
    computed: {
        retornarNumeroMascarado(){
            return (numero, regex, mascara) => {
                if(!numero){
                return "";
                }

                return numero.toString().replace(new RegExp(regex, "g"), mascara);
            }
        },
        retornarLinkWhatsapp(){
            return () => {
                return this.$store.state.configPadrao.linkWhatsapp + this.nroSuporte;
            }
        }
    }
}
</script>
