<template>
  <div class="app flex-row align-items-center" :class="retornarClasseBackground()">
    <loading :active.sync="isLoading"></loading>
    <div class="container">
      <b-row class="justify-content-center row-login">
        <img src="./../assets/img/logo-union-login.png" alt="Logo Union" class="logo-union">
        <b-col :md="this.loggedIn ? '6' : '4'" v-if="!objEstaVazio(manutencao)" id="container-aviso-manutencao">
          <p class="info-manutencao">
            <i class="fa fa-cog"></i> Sistema em manutenção
          </p>
        </b-col>
        <b-col :md="this.loggedIn ? '6' : '4'">
          
          <b-card-group>
            <b-card v-if="!loggedIn" no-body class="card-login p-4">
              <b-card-body>
                <b-form v-on:submit.prevent="noop">
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-user"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email"
                      autocomplete="username email"
                      v-model="email"
                    />
                  </b-input-group>
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-lock"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Senha"
                      autocomplete="current-password"
                      v-model="password"
                    />
                  </b-input-group>
                  <b-row class="mb-4" id="row-forget-password">
                    <b-col cols="6" class="text-left">
                      <router-link to="/senha"><b-button variant="link" style="color: white; font-weight: bold;" class="px-0"
                        >Esqueceu sua senha?</b-button
                      ></router-link>
                    </b-col>
                  </b-row>
                  <b-row class="group" id="row-btn-login">
                    <b-col cols="5">
                      <b-button
                        type="submit"
                        v-on:click="login()"
                        v-on:keyup.enter="login()"
                        class="px-4 btn-login"
                        >ENTRAR</b-button
                      >
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
            <resale-selector v-else
              :empresas="resales" 
              :usuario="user" 
              :dataExpiracao="expirationDate"
              :nroSuporte="whatsapp"
              @alterarLoggedIn="alterarLoggedIn"></resale-selector> 
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style scoped>
  .app {
    background-size: cover;
    background-repeat: no-repeat;
  }

  .background-padrao {
    background-image: url("./../assets/img/fundo_login.png");
  }

  .background-manutencao {
    background-image: url("./../assets/img/manutencao.png");
    background-position: center;
  }

  .row-login {
    flex-direction: column;
    align-items: center;
  }
  .card {
    background-color: transparent;
    border: none;
  }
  .card-body{
    padding: 0!important;
  }
  .card-login {
    margin-top: 3rem;
    padding: 1em!important;
    border-radius: 1em;
  }
  .input-group-text, .btn-item-empresa{
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    border-color: orangered;
  }
  .input-group-text i {
    color: orangered
  }
  .input-group input, .btn-item-empresa {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    border-color: orangered;
  }
  #row-btn-login {
    justify-content: center;
    display: flex;
  }
  .btn-login, .btn-voltar {
    border-color: rgba(255, 68, 0, 0.897);
    background-color: orangered;
    color: white;
    border-radius: 50px;
    padding: 0 1em;
    width: 100%;
  }
  .btn-login:hover, .btn-voltar:hover {
    background-color: rgba(255, 68, 0, 0.808);
  }
  .btn-voltar {
    width: auto;
  }
  .estilo-empresa{
    font-size: 1.2rem;
    color: #fff;
  }
  .margin-bottom{
    margin-bottom: 15px !important;
  }

  #container-aviso-manutencao ~ .col-md-4 .card-login {
    margin-top: 0!important;
  }

  #container-aviso-manutencao .fa-cog {
    margin-right: 10px;
  }

  .info-manutencao {
    margin-top: 1rem;
    font-size: 24px;
    text-align: center;
    background-color: lightblue;
    border-radius: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

</style>

<script>
import ResaleSelector from "../components/ResaleSelector.vue";
import Autenticar from "../services/autenticar";
import Carrinho from "../services/carrinho";

export default {
  components: {ResaleSelector},
  props: ['empresas', 'usuario', 'dataExpiracao', 'nroSuporte'],
  name: "login",
  data() {
    return {
      loggedIn: false,
      dismissSecs: 10,
      dismissCountDown: 0,
      email: "",
      password: "",
      resales: [],
      user: null,
      expirationDate: null,
      manutencao: {},
      carregandoChecagemManutencao: true,
      whatsapp: ""
    };
  },
  mounted() {
    this.$store.state.ui.loading = false;
    this.validarSistemaEmManutencao();
    this.buscarWhatsappSuporte();

    if (this.$store.state.ui.msg && this.$store.state.ui.msg_type) {
      this.$helper.showMsg(
        this.$store.state.ui.msg,
        this.$store.state.ui.msg_type
      );
      this.$store.commit("ui/MSG", null);
      this.$store.commit("ui/MSG_TYPE", null);
    }
  },
  computed: {
    isLoading:function(){
      if (
          this != null &&
          this.$store != null &&
          this.$store.state != null &&
          this.$store.state.ui != null &&
          this.$store.state.ui.loading != null &&
          !this.carregandoChecagemManutencao
        ){
          return this.$store.state.ui.loading;
        }
        return false;
    },
    objEstaVazio(){
      return obj => {
        return Object.keys(obj).length === 0;
      }
    },
    retornarClasseBackground() {
      return () => {
        if(!this.objEstaVazio(this.manutencao)){
          return "background-manutencao";
        }

        return "background-padrao";
      }
    }
  },
  methods: {
    voltar() {
      this.loggedIn = false;
    },
    clearForm() {
      this.email = "";
      this.password = "";
    },
    useResale(resale) {
      this.$store.commit("login/CURRENT_RESALE", resale);
      this.$store.commit("login/CURRENT_USER", this.user);
      this.$store.commit("login/X_API_KEY", resale.key);
      this.$store.commit("login/EXPIRATION_DATE", this.expirationDate);
      this.loadCurrentCart();
      this.$router.push("/");
    },
    loadCurrentCart() {
      this.$store.commit("carrinho/RESET");
      Carrinho.getCarrinho()
        .then(response => {
          this.$store.commit("carrinho/CURRENT_CART", response.data.data);
        })
        .catch(() => {});
    },
    clearLoginInfo() {
      this.$store.commit("ui/RESET");
      this.$store.commit("login/RESET");
      this.$store.commit("carrinho/RESET");
      localStorage.clear();
    },
    login() {
      if (this.email == "" || this.password == "") {
        this.$helper.showMsg("Preencha Usuário / Senha.", "warning");
        return;
      }

      this.resales = [];
      Autenticar.login(this.email, this.password)
        .then(response => {
          this.user = null;
          this.expirationDate = null;
          this.loggedIn = false;
          this.resales = response.data.keys;
          this.user = response.data.user;
          this.expirationDate = response.data.expiration_date;
          if(this.resales.length == 1){
            this.useResale(this.resales[0]);
          } else {
            this.loggedIn = true;
          }
          
        })
        .catch(e => {
          this.clearLoginInfo();
          this.loggedIn = false;
          this.resales = [];
          this.$helper.showErrorResponse(e);
          this.clearForm();
        });
      
    },
    noop () {
    },
    validarSistemaEmManutencao(){
      this.carregandoChecagemManutencao = true;

      Autenticar.validarManutencao()
        .then( (response) => {
          if(response.data && response.data.manutencao){
            this.manutencao = response.data.manutencao;
          }
          
        })
        .catch(error => {
          this.manutencao = {}
          console.error(error);
        })
        .finally(() => {
          this.carregandoChecagemManutencao = false;
        });
    },
    buscarWhatsappSuporte(){
      Autenticar.buscarTelefoneSuporte()
        .then( response => {
            this.whatsapp = response.data.data["whatsapp"];
          }
        );
    },
    alterarLoggedIn() {
      this.loggedIn = !this.loggedIn;
      this.clearForm();
      this.clearLoginInfo();
    }
  }
};
</script>
